import React, { Fragment, lazy, Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from 'redux/storeConfig/store'
import { useSelector } from 'react-redux'
// ** International Language
import { IntlProviderWrapper } from './utils/context/Internationalization'
// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
import Loader from "react-loader-spinner";
import Router from "router/Router";

// EMC Tracking Code
const EMCTracking = () => {
  useEffect(() => {
    var _govaq = window._govaq || [];
    _govaq.push(['trackPageView']);
    _govaq.push(['enableLinkTracking']);
    _govaq.push(['setTrackerUrl', 'https://f-emc.ngsp.gov.vn/tracking']);
    _govaq.push(['setSiteId', '4139']);
    
    (function() {
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = 'https://f-emc.ngsp.gov.vn/embed/gov-tracking.min.js';
      s.parentNode.insertBefore(g, s);
    })();
  }, []);
  
  return null;
};

// ** Lazy load app
const LazyApp = lazy(() => import('./router/Router'))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader type="ThreeDots" color="#285A21" height="100" width="100" />}>
      <IntlProviderWrapper>
        {/* EMC Tracking */}
        <EMCTracking />
        <LazyApp />
      </IntlProviderWrapper>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
