/*eslint-disable*/
import React from "react";
import 'assets/css/footer.css';
import {Link} from 'react-router-dom'
// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import { faYoutube, faFacebook, faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
function DemoFooter() {
  const storeInfo = useSelector(state => state.login)
  return (
    // storeInfo.login ?
      <footer className="site-footer" >
        <Container style={{ paddingBottom: '55px' }}>
          <Row>
            <Col md={4} sm={12}>
              <p className="text-uppercase titleSchool">Le quy don</p>
              <p className="titleUniver">Technical University</p>
              <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} /> <span className="addressUniver">236 Hoang Quoc Viet, Ha Noi, Viet Nam</span>
              </p>
            </Col>
            <Col md={2} sm={12} >
              <ul className='listLink' style={{marginLeft:'0'}}>
                <li>
                  <a href="/page/Home/faculties">Faculties</a>
                </li>
                <li>
                  <a href="/page/Home/institutes-and-centers">Institutes/ Centers</a>
                </li>
                <li>
                  <a href="/page/Home/offices">Offices</a>
                </li>
              </ul>
            </Col>
            {/* <Col  sm={6}>
              <ul className='listLink'>
                <li>
                  <a >Annual Report</a>
                </li>
                <li>
                  <a href='/page/Home/overview'>Contact</a>
                </li>
                <li>
                  <a >Sitemap</a>
                </li>
              </ul>
            </Col> */}
            <Col md={4} sm={12}>
              <p className="conUs">Connect with Us</p>
              <div className="d-flex mediaCon">
                <ul className="list-unstyled mr-5">
                  <li>
                    <FontAwesomeIcon icon={faFacebook} /> Facebook
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faInstagram} /> Instagram
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faLinkedin} /> Linkedin
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faYoutube} /> Youtube
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={2} sm={12}>
            <a href="https://info.flagcounter.com/cJdx"><img src="https://s11.flagcounter.com/count2/cJdx/bg_FFFFFF/txt_000000/border_CCCCCC/columns_2/maxflags_10/viewers_0/labels_0/pageviews_0/flags_0/percent_0/" alt="Flag Counter" border="0"></img></a>
            </Col>
            
          </Row>
        </Container>
        <p className="mb-0 text-center copyright">
          © 2021 Le Quy Don Technical University. All Rights Reserved
        </p>
      </footer> 
      // : <></>
  );
}

export default DemoFooter;
